import React from 'react'
import { push } from 'connected-react-router'
import { withTranslation, WithTranslation } from 'react-i18next'

import { showNotify } from 'utils/notify'
import { generateContract } from 'models/Contract'
import * as actions from 'store/cart/actions'
import { updateContract } from 'store/contracts/actions'
import * as simulatorActions from 'store/simulator/actions'
import BagInfo from './BagInfo'
import BenefitInfo from './BenefitInfo'
import CartButtons from './CartButtons'
import ContractButtons from './ContractButtons'
import ContractChangeConfirm from './ContractChangeConfirm'

interface Props {
  benefit: Benefit
  cart: Contract[]
  EditComponent: any
  simulation: Simulation
  hideBagInfo?: boolean
  family: Family[]
  dispatch(action: any): any
}

interface States {
  contract: Contract
  isEdit: boolean
  isInformative: boolean
}

class BenefitDetail extends React.Component<Props & WithTranslation, States> {
  static defaultProps = {
    hideBagInfo: false,
  }

  cartItem: Contract = null
  simulatorId = null

  constructor(props) {
    super(props)

    const { benefit, simulation } = this.props

    this.cartItem = this.getCartItem()
    let contract: Contract

    if (this.cartItem) {
      contract = generateContract({ ...this.cartItem, simulation })
    } else {
      contract = generateContract({ price: 0, benefit, simulation })
    }
    // assign new id
    contract.generateId()
    this.state = {
      contract,
      isEdit: true,
      isInformative: contract.product === 'informative' || (contract.benefit && contract.benefit.contractMethod === 'informative')
    }
  }

  componentDidMount() {
    this.props.dispatch(simulatorActions.addItem(this.state.contract))
  }

  componentWillUnmount() {
    this.props.dispatch(simulatorActions.removeItem(this.state.contract.id))
  }

  getCartItem = () => {
    const { benefit, cart } = this.props

    return cart.find(item => item.benefit.modalityId === benefit.modalityId)
  }

  addToCart = () => {
    const { t } = this.props
    const { contract } = this.state

    if (!this._checkCartAmounts(contract,t)) {
      return
    }

    this.props.dispatch(actions.addItem(contract.toJSON(), contract.benefit))
  }

  _checkCartAmounts = (contract, t) => {
    if (!contract.price) {
      showNotify(t('contracts.zero_message'), 'error')
      return false
    }
    if (contract.benefit.minAmount) {
      for(const monthItem of contract.items){
        if(monthItem.price < contract.benefit.minAmount){
          showNotify(t('contracts.min_unreached'), 'error')
          return false
        }
      }
    }
    return true
  }

  updateCart = () => {
    const { t } = this.props
    const { contract } = this.state

    if (!this._checkCartAmounts(contract,t)) {
      return
    }

    this.props.dispatch(actions.updateItem(this.cartItem.id, contract.toJSON()))
  }

  removeFromCart = () => {
    this.props.dispatch(actions.removeItem(this.cartItem.id))
  }

  handleCartActions = action => {
    const { benefit, t } = this.props
    const { contract } = this.state

    if (action === 'add') {
      this.addToCart()
    } else if (action === 'update') {
      this.updateCart()
    } else if (action === 'remove') {
      this.removeFromCart()
    } else if (action === 'reset') {
      // eslint-disable-next-line
      this.setState({
        contract: generateContract(this.cartItem || { price: 0, benefit }),
      })
      this.props.dispatch(simulatorActions.removeItem(contract.id))

      showNotify(t('contracts.reset_message'))
    }
  }

  showConfirm = () => {
    const { t, dispatch } = this.props
    const { contract } = this.state

    if (contract.benefit.minAmount && contract.price) {
      for(const monthItem of contract.items){
        if(monthItem.price < contract.benefit.minAmount){
          showNotify(t('contracts.min_unreached'), 'error')
          return
        }
      }
    }

    if (!contract.price) {
      if (!window.confirm(t('contracts.cancel_confirm'))) return
      dispatch(updateContract(this.cartItem.id, contract)).then(() => {
        dispatch(push('/home'))
      })
    } else if (contract.isCancelled()) {
      dispatch(updateContract(this.cartItem.id, contract)).then(() => {
        dispatch(push('/home'))
      })
    } else {
      this.setState({ isEdit: false })
    }
  }

  showEdit = () => {
    this.setState({ isEdit: true })
  }

  handleChange = () => {
    const { contract } = this.state
    this.props.dispatch(
      simulatorActions.updateItem(contract)
    )
  }

  handleUpdateContract = (additionalInfo: any) => {
    const { t, dispatch } = this.props
    const { contract } = this.state
    const tempContract = generateContract({ ...contract, additionalInfo })

    if (tempContract.isMissingDocument()) {
      showNotify(
        'Falta un documento. Por favor llénalo para continuar',
        'error'
      )
      return
    }

    if (!window.confirm(t('contracts.update_confirm_message'))) return

    dispatch(updateContract(this.cartItem.id, tempContract)).then(() => {
      dispatch(push('/home'))
    })
  }

  isEditable = (): boolean => {
    if (!this.cartItem || this.cartItem.isCart()) return true

    function isVariableProduct(contract) {

      return contract
          && contract.benefit
          && contract.benefit.product
          && contract.benefit.product.category
          && contract.benefit.product.category.isDefault === false
    }

    const { contract } = this.state

    return (
        ['food','kindergarten','transport','health-insurance','saving-plan'].includes(contract.productCategory)
        || isVariableProduct(contract)
    )
  }

  renderEditDetail= () => {
    const { EditComponent, hideBagInfo, t } = this.props
    const { contract, isInformative } = this.state

    return (
      <div className="mb-5">
        <div className="product-info">
          {!hideBagInfo && (
            <React.Fragment>
              <BenefitInfo benefit={contract.benefit} />
              {!isInformative ? (
                <BagInfo
                  benefit={contract.benefit}
                  showEmployBag={contract.benefit.isTaxAdvantage}
                  showTopBag={!contract.benefit.isTaxAdvantage}
                />
              ) : null}
            </React.Fragment>
          )}

          {!isInformative && (
            <EditComponent
              {...this.props}
              contract={contract}
              onChange={this.handleChange}
              editable={this.isEditable()}
            />
          )}

          {this.cartItem && !this.cartItem.isCart() && !isInformative && (
            <React.Fragment>
              <div>{t(this.cartItem.getStatusText())}</div>
              {this.isEditable() && (
                <ContractButtons onClick={this.showConfirm} />
              )}
            </React.Fragment>
          )}

          {(!this.cartItem || this.cartItem.isCart()) && !isInformative && (
            <CartButtons
              isUpdate={!!this.cartItem}
              onClick={this.handleCartActions}
            />
          )}

        </div>
        <hr />
      </div>
    )
  }

  renderOtherDetail = () => {
    const {  hideBagInfo } = this.props
    const { contract } = this.state
    return (
      <div className="mb-5">
        <div className="product-info">
          {!hideBagInfo && (
            <React.Fragment>
              <BenefitInfo benefit={contract.benefit} />
              <BagInfo
                benefit={contract.benefit}
                showEmployBag={contract.benefit.isTaxAdvantage}
                showTopBag={!contract.benefit.isTaxAdvantage}
              />
            </React.Fragment>
          )}
        </div>
        <ContractChangeConfirm
          contract={contract}
          onBack={this.showEdit}
          onUpdate={this.handleUpdateContract}
        />
      </div>
    )
  }

  render() {
    const { isEdit } = this.state

    this.cartItem = this.getCartItem()

    return isEdit ? this.renderEditDetail() : this.renderOtherDetail()
  }
}

export default withTranslation()(BenefitDetail)
