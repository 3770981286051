import React from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  isUpdate: boolean
  onClick(action: string): void
}

const CartButtons: React.FC<Props> = ({ onClick, isUpdate }) => {
  const { t } = useTranslation()

  return (

      <div>
        <div className="grey-bar">{t('buttons.select')}</div>
        <div className="bag-box">


          {!isUpdate ? (
              <React.Fragment>
                <button
                    type="button"
                    className="btn-none mr-4"
                    onClick={() => {
                      onClick('add')
                    }}
                >
                  <img src="/img/cart.jpg" alt="btn" width="50" />{' '}
                  {t('buttons.add_cart')}
                </button>
                <button
                    type="button"
                    className="btn-none mr-4"
                    onClick={() => {
                      onClick('reset')
                    }}
                >
                  <img src="/img/reset.jpg" alt="btn" width="50" />{' '}
                  {t('buttons.reset')}
                </button>
              </React.Fragment>
          ) : (
              <React.Fragment>
                <button
                    type="button"
                    className="btn-none mr-4"
                    onClick={() => {
                      onClick('update')
                    }}
                >
                  <img src="/img/cart.jpg" alt="btn" width="50" />
                  {t('buttons.update_cart')}
                </button>
                <button
                    type="button"
                    className="btn-none"
                    onClick={() => {
                      onClick('remove')
                    }}
                >
                  <img src="/img/remove.jpg" alt="btn" width="50" />
                  {t('buttons.remove_cart')}
                </button>
              </React.Fragment>
          )}

        </div>
    </div>
  )
}

export default CartButtons
