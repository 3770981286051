import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import access from 'safe-access'

import { formatCurrency, strToFloat } from 'utils/number'
import { isProductLimitNotReached } from 'utils/contracts'
import { showNotify } from 'utils/notify'
import { getTable } from 'store/company/actions'
import MonthSelect from 'components/BenefitDetail/MonthSelect'
import ContractPrice from "../../../components/BenefitDetail/ContractPrice"


/*
   SUMATORIO Y CÁLCULO DE PRIMAS
   -----------------------------
    FALL CC: Fallecimiento por cualquier causa ( obligatorio para contratar Combo 1 y IPA CC)

   Combo 1 :
      * FALL A: Fallecimiento por accidente
      *FALL AC: Fallecimiento por accidente  + accidente de circulación

  IPA CC: Incapacidad Permanente Absoluta por cualquier causa (obligatorio contratar si se Quiere contratar el combo 2)

   Combo 2:
      * IPA A:  Incapacidad Permanente Absoluta por accidente
       * IPA AC: Incapacidad Permanente Absoluta por accidente + accid circu

  Gran Invalidez

  Opciones acumulativas
*/

interface Props {
  contract: Contract
  editable?: boolean
  simulation: Simulation
  user: User
  table: InsuranceTableAxa
  onChange: () => void
  dispatch: (action: any) => Promise<any> | void
}


class LifeInsuranceAxa extends React.Component<Props & WithTranslation> {
  static defaultProps = {
    editable: true,
  }
  private month: any;

  constructor(props) {
    super(props)
    this.props.dispatch(getTable('life-axa'))
  }


  componentDidUpdate(prevProps) {
    if( prevProps.table &&
        (prevProps.table.FALLA !== this.props.table.FALLA
            || prevProps.table.FALLAC !== this.props.table.FALLAC
            || prevProps.table.FALLCC !== this.props.table.FALLCC
            || prevProps.table.GINV !== this.props.table.GINV
            || prevProps.table.IPAA !== this.props.table.IPAA
            || prevProps.table.IPAAC !== this.props.table.IPAAC
            || prevProps.table.IPACC !== this.props.table.IPACC
        )) {
      this.calculatePrice('startDate', this.month)
      return true
    }
    return false
  }

  private calculatePrice = (name, value) => {
    const {
      contract,
      simulation: { flexibleBag, topBag, fixedSalary },
    } = this.props
    const isTaxAdvantage = access(contract, 'benefit.isTaxAdvantage')
    let contractBag = isTaxAdvantage ? flexibleBag : topBag
    if (!isTaxAdvantage && !topBag) {
      contractBag = fixedSalary
    }

    let { table } = this.props
    const oldDetails = contract.details
    const updatedDetails = {
      ...contract.details,
      [name]: value,
    }
    if (!this.props.editable) {
      return
    }
    const isFALLCCset = !!(updatedDetails.fallcc)
    const isIPACCset = !!(updatedDetails.ipacc)
    const isIPAAset = !!(updatedDetails.ipaa)
    const isIPAACset = !!(updatedDetails.ipaac)


    function checkLimit(from, to) {
      return from.reduce((prev, prop)=>{
        return prev || strToFloat(updatedDetails[prop]) > strToFloat(updatedDetails[to])
      },false)
    }


    const isNotValid = (!isFALLCCset) || ((isIPAAset || isIPAACset) && !isIPACCset)
        || checkLimit(['falla','fallac','ipacc','ipaa','ipaac','ginv'], 'fallcc')
        || checkLimit(['ipaa','ipaac'], 'ipacc')


    if(isNotValid) {
      updatedDetails.price = 0
      contract.updateDetails(updatedDetails)
      this.props.onChange()
      return
    }

    //checking age limit
    updatedDetails.price =
        (strToFloat(updatedDetails.fallcc) * strToFloat(table.FALLCC) / 1000)
        + (strToFloat(updatedDetails.falla) * strToFloat(table.FALLA) / 1000)
        + (strToFloat(updatedDetails.fallac) * strToFloat(table.FALLAC) / 1000)
        + (strToFloat(updatedDetails.ipacc) * strToFloat(table.IPACC) / 1000)
        + (strToFloat(updatedDetails.ipaa) * strToFloat(table.IPAA) / 1000)
        + (strToFloat(updatedDetails.ipaac) * strToFloat(table.IPAAC) / 1000)
        + (strToFloat(updatedDetails.ginv) * strToFloat(table.GINV) / 1000)


    updatedDetails.table = table

    contract.updateDetails(updatedDetails)

    if (contract.price > contractBag) {
      contract.updateDetails(oldDetails)
      showNotify('El precio ha excedido la cantidad disponible')
    } else {
      this.props.onChange()
    }
  }

  handleChange = (name: string, value: any) => {
    this.calculatePrice(name, value)
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.handleChange(e.target.name, e.target.value)
  }



  render() {
    const { contract, editable, table, simulation, t } = this.props
    const { benefit, details } = contract

    /**
     *     "Edad": "38",
     *     "FALLCC": "0,441718849",
     *     "FALLA": "0,4",
     *     "FALLAC": "0,25",
     *     "IPACC": "0,044677358",
     *     "IPAA": "0,32",
     *     "IPAAC": "0,2",
     *     "GINV": "0,024"
     */
    const isFALLCCset = !!(details.fallcc)
    const isFALLAset = !!(details.falla)
    const isFALLACset = !!(details.fallac)
    const isIPACCset = !!(details.ipacc)
    const isIPAAset = !!(details.ipaa)
    const isIPAACset = !!(details.ipaac)
    const isGINVset = !!(details.ginv)

    const isFALLCCavailable = !!(table && table.FALLCC)
    const isFALLAavailable = !!(table && table.FALLA)
    const isFALLACavailable = !!(table && table.FALLAC)
    const isIPACCavailable = !!(table && table.IPACC)
    const isIPAAavailable = !!(table && table.IPAA)
    const isIPAACavailable = !!(table && table.IPAAC)
    const isGINVavailable = !!(table && table.GINV)


    if(!isFALLCCavailable) {
      details.fallcc = 0
    }
    if(!isFALLAavailable) {
      details.falla = 0
    }
    if(!isFALLACavailable) {
      details.fallac = 0
    }
    if(!isIPACCavailable) {
      details.ipacc = 0
    }
    if(!isIPAAavailable) {
      details.ipaa = 0
    }
    if(!isIPAACavailable) {
      details.ipaac = 0
    }
    if(!isGINVavailable) {
      details.ginv = 0
    }

    function checkLimitFALLCC(val) {
      return strToFloat(val) > strToFloat(details.fallcc)
    }
    
    function checkMaxFALLCC() {
      return strToFloat(details.fallcc) < 35000
    }

    function checkLimitFALLA(val) {
      return strToFloat(val) > strToFloat(details.falla)
    }

    function checkLimitIPACC(val) {
      return strToFloat(val) > strToFloat(details.ipacc)
    }

    function checkLimitIPAA(val) {
      return strToFloat(val) > strToFloat(details.ipaa)
    }



    const isLimitNotReached = isProductLimitNotReached(contract, simulation)

    return isLimitNotReached ? (
      <React.Fragment>

        <div className="contract-body-alt">

          <div className="d-flex flex-column mb-2">
            <div className="d-flex flex-column">
                <label>{t('LIFE_AXA.FALLCC')} (FALLCC)</label>

                <div>
                  <div className="input-group embed">
                    <input
                        type="number"
                        className="form-control"
                        name="fallcc"
                        value={access(contract, 'details.fallcc') || ''}
                        onChange={this.handleInputChange}
                        disabled={!table || !editable || !isFALLCCavailable}
                    />
                    <span className="input-group-addon">€</span>
                  </div>

                </div>

                { !isFALLCCset && <span className="text-danger">*{t('LIFE_AXA.MANDATORY')}</span> }
                { !isFALLCCavailable && <span className="text-danger">*{t('LIFE_AXA.AGE_LIMIT')}</span> }
                { checkMaxFALLCC() && <span className="text-danger">*{t('LIFE_AXA.CANNOT_LESS_FALLA')}</span> }


              </div>

            <div className="ml-5 d-flex">
              <i className="entypo-level-down mt-5"></i>
              <div className="d-flex flex-column">
                <label>{t('LIFE_AXA.FALLA')} (FALLA)</label>

                <div>
                  <div className="input-group embed">
                    <input
                        type="number"
                        className="form-control"
                        name="falla"
                        value={access(contract, 'details.falla') || ''}
                        onChange={this.handleInputChange}
                        disabled={!table || !editable || !isFALLAavailable}
                    />
                    <span className="input-group-addon">€</span>
                  </div>

                </div>

                { !isFALLCCset &&  isFALLAset && <span className="text-danger">*FALLCC {t('LIFE_AXA.MANDATORY_2')} FALLA</span> }
                { !isFALLAavailable && <span className="text-danger">*{t('LIFE_AXA.AGE_LIMIT')}</span> }
                { checkLimitFALLCC(details.falla) && <span className="text-danger">*FALLA {t('LIFE_AXA.CANNOT_EXCEED')} FALLCC</span> }

              </div>

            </div>

            <div className="ml-5 d-flex">
              <i className="entypo-level-down mt-5"></i>
              <div className="d-flex flex-column">
                <label>{t('LIFE_AXA.FALLAC')} (FALLAC)</label>

                <div>
                  <div className="input-group embed">
                    <input
                        type="number"
                        className="form-control"
                        name="fallac"
                        value={access(contract, 'details.fallac') || ''}
                        onChange={this.handleInputChange}
                        disabled={!table || !editable || !isFALLACavailable}
                    />
                    <span className="input-group-addon">€</span>
                  </div>

                </div>

                { !isFALLCCset &&  isFALLACset && <span className="text-danger">*FALLCC {t('LIFE_AXA.MANDATORY_2')} FALLAC</span> }
                { !isFALLAset &&  isFALLACset && <span className="text-danger">*FALLA {t('LIFE_AXA.MANDATORY_2')} FALLAC</span> }
                { !isFALLAavailable && <span className="text-danger">*{t('LIFE_AXA.AGE_LIMIT')}</span> }
                { checkLimitFALLCC(details.fallac) && <span className="text-danger">*FALLAC {t('LIFE_AXA.CANNOT_EXCEED')} FALLCC</span> }
                { isFALLAset && checkLimitFALLA(details.fallac) && <span className="text-danger">*FALLAC {t('LIFE_AXA.CANNOT_EXCEED')} FALLA</span> }

              </div>

            </div>

          </div>

          <div className="d-flex flex-column mb-2">
            <div className="d-flex flex-column">
              <label>{t('LIFE_AXA.IPACC')} (IPACC)</label>

              <div>
                <div className="input-group embed">
                  <input
                      type="number"
                      className="form-control"
                      name="ipacc"
                      value={access(contract, 'details.ipacc') || ''}
                      onChange={this.handleInputChange}
                      disabled={!table || !editable || !isIPACCavailable}
                  />
                  <span className="input-group-addon">€</span>
                </div>

              </div>

              { !isFALLCCset && isIPACCset && <span className="text-danger">*FALLCC {t('LIFE_AXA.MANDATORY_2')} IPACC</span> }
              { !isIPACCavailable && <span className="text-danger">*{t('LIFE_AXA.AGE_LIMIT')}</span> }
              { checkLimitFALLCC(details.ipacc) && <span className="text-danger">*IPACC {t('LIFE_AXA.CANNOT_EXCEED')} FALLCC</span> }


            </div>

            <div className="ml-5 d-flex">
              <i className="entypo-level-down mt-5"></i>
              <div className="d-flex flex-column">
                <label>{t('LIFE_AXA.IPAA')} (IPAA)</label>

                <div>
                  <div className="input-group embed">
                    <input
                        type="number"
                        className="form-control"
                        name="ipaa"
                        value={access(contract, 'details.ipaa') || ''}
                        onChange={this.handleInputChange}
                        disabled={!table || !editable || !isIPAAavailable}
                    />
                    <span className="input-group-addon">€</span>
                  </div>

                </div>

                { !isIPACCset &&  isIPAAset && <span className="text-danger">*IPACC {t('LIFE_AXA.MANDATORY_2')} IPAA</span> }
                { !isIPAAavailable && <span className="text-danger">*{t('LIFE_AXA.AGE_LIMIT')}</span> }
                { checkLimitIPACC(details.ipaa) && <span className="text-danger">*IPAA {t('LIFE_AXA.CANNOT_EXCEED')} IPACC</span> }

              </div>

            </div>

            <div className="ml-5 d-flex">
              <i className="entypo-level-down mt-5"></i>
              <div className="d-flex flex-column">
                <label>{t('LIFE_AXA.IPAAC')} (IPAAC)</label>

                <div>
                  <div className="input-group embed">
                    <input
                        type="number"
                        className="form-control"
                        name="ipaac"
                        value={access(contract, 'details.ipaac') || ''}
                        onChange={this.handleInputChange}
                        disabled={!table || !editable || !isIPAACavailable}
                    />
                    <span className="input-group-addon">€</span>
                  </div>

                </div>

                { !isIPACCset &&  isIPAACset && <span className="text-danger">*IPACC {t('LIFE_AXA.MANDATORY_2')} IPAAC</span> }
                { !isIPAAset &&  isIPAACset && <span className="text-danger">*IPAA {t('LIFE_AXA.MANDATORY_2')} IPAAC</span> }
                { !isIPAACavailable && <span className="text-danger">*{t('LIFE_AXA.AGE_LIMIT')}</span> }
                { checkLimitIPACC(details.ipaac) && <span className="text-danger">*IPAAC {t('LIFE_AXA.CANNOT_EXCEED')} IPACC</span> }
                { isIPAAset && checkLimitIPAA(details.ipaac) && <span className="text-danger">*IPAAC {t('LIFE_AXA.CANNOT_EXCEED')} IPAA</span> }

              </div>
            </div>

          </div>

          <div className="d-flex flex-column mb-2">
            <div className="d-flex flex-column">
              <label>{t('LIFE_AXA.GINV')} (GINV)</label>

              <div>
                <div className="input-group embed">
                  <input
                      type="number"
                      className="form-control"
                      name="ginv"
                      value={access(contract, 'details.ginv') || ''}
                      onChange={this.handleInputChange}
                      disabled={!table || !editable || !isGINVavailable}
                  />
                  <span className="input-group-addon">€</span>
                </div>

              </div>

              { !isFALLCCset && isGINVset && <span className="text-danger">*FALLCC {t('LIFE_AXA.MANDATORY_2')} GINV</span> }
              { !isGINVavailable && <span className="text-danger">*{t('LIFE_AXA.AGE_LIMIT')}</span> }
              { checkLimitFALLCC(details.ginv) && <span className="text-danger">*GINV {t('LIFE_AXA.CANNOT_EXCEED')} FALLCC</span> }

            </div>

          </div>


          <label>{t('Mes inicio de contratación')}</label>

            <MonthSelect
              availability={benefit.monthsAvailability}
              value={details.startDate}
              onChange={month => {
                this.handleChange('startDate', month)
              }}
              disabled={!editable}
            />



          <div className="d-flex my-3">

                <div className="text-center">
                      <span className="benefit-prima-anual">
                        {t('products.yearly_price')}{' '}
                          {formatCurrency(access(contract, 'details.price'))}
                      </span>
                </div>

              <ContractPrice price={contract.price} discount={contract.userBag}/>


            </div>
        </div>

      </React.Fragment>
    ) : (
      <div className="alert alert-danger" role="alert">
          {t('HEADER.LIMIT')} {t('HEADER.REACHED')}
      </div>
    )
  }
}

export default withTranslation()(LifeInsuranceAxa)
