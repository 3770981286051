import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { withTranslation, WithTranslation } from 'react-i18next'
import { createProductDoc, createUserDoc, createProductAnswers } from 'store/app/actions'
import Dropzone from 'components/Dropzone'
import Panel from 'components/Panel'
import ServerFileLink from 'components/ServerFileLink'
import ProductQuestions from 'components/ProductQuestions'

/**
 *
 */
interface Props {
    additionalInfo: any
    benefit: Benefit
    contract: Contract
    employeeName?: string
    user?: User
    dispatch(action: any): any
    onUpdate(values: any): void
}

/**
 *
 */
interface States {
    file: any
    modal: boolean
    showProductAnswers: boolean
}

/**
 *
 */
class AdditionalInfoColumn extends React.Component<Props & WithTranslation, States> {

    /**
     *
     */
    constructor(props) {
        super(props)

        this.state = {
            modal: false,
            file: props.additionalInfo,
            showProductAnswers: false
        }
    }

    toggleModal = () => {
        this.setState({
            modal: !this.state.modal,
        })
    }

    onUpdateInfo = () => {
        this.setState({
            modal: true,
        })
    }

    removeFile = () => {
        this.setState({
            file: null,
        })
    }

    handleFileUpload = file => {
        this.setState({ file })
    }

    handleUpdate = () => {
        const { onUpdate, user } = this.props

        onUpdate(this.state.file)
        createUserDoc(user.id, this.state.file)

        this.setState({ modal: false })
    }

    toggleProductAnswers() {
        return () => {
            this.setState(prev => {
                const { showProductAnswers } = prev

                return {
                    showProductAnswers: !showProductAnswers
                }
            })
        }
    }

    handleQuestionsSubmit() {
        return values => {
            this.props.onUpdate(values)

            const {
                dispatch,
                user: { id: userId },
                contract,
                benefit: {
                    product: { id: productId }
                }
            } = this.props

            const contractId = contract.id

            if (values.file) {
                dispatch(createProductDoc(userId, { ...values, ...values.file }))
                    .then(() => {
                        if (values.answer) {
                            dispatch(createProductAnswers(contractId, userId, productId,{ ...values, ...values.file }))
                                .then(() => {
                                    this.toggleProductAnswers()()
                                })
                                .catch((e) => console.log(e))
                        } else {
                            this.toggleProductAnswers()()
                        }
                    })
                    .catch(err => console.log(err))
            } else {
                dispatch(createProductAnswers(contractId, userId, productId, { ...values, ...values.file }))
                    .then(() => {
                        this.toggleProductAnswers()()
                    })
                    .catch((e) => console.log(e))
            }
        }
    }

    render() {
        const { additionalInfo, employeeName, benefit, benefit: { product }, t } = this.props
        const { modal, file, showProductAnswers } = this.state
        const isSurvey = product.isDocumentRequired || product.isQuestionsRequired

        if (isSurvey) {
            return !Object.keys(additionalInfo).length
                ? (
                    <>
                        <img src="/img/error.jpg" alt="cart" width="50"/>
                        <span className="label label-danger">{t('cart.pending')}</span>
                        <br/>
                        <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => this.setState({
                                showProductAnswers: true
                            })}
                        >
                            <span>ACTUALIZAR </span>
                            <i className="entypo-pencil" />
                        </button>
                        {showProductAnswers && (
                            <ProductQuestions
                                toggle={this.toggleProductAnswers()}
                                product={benefit.product}
                                onSubmit={this.handleQuestionsSubmit()}
                            />
                        )}
                    </>
                )
                : (
                    <>
                        <img src="/img/ok.jpg" alt="cart" width="50"/>
                        <span className="label label-success">{t('cart.completed')}</span>
                        <br/>
                        <button
                            type="button"
                            className="btn btn-default"
                            onClick={() => this.setState({
                                showProductAnswers: true
                            })}
                        >
                            <span>{t('buttons.update')}</span>
                            <i className="entypo-pencil" />
                        </button>

                        {showProductAnswers && (
                            <ProductQuestions
                                initialValues={additionalInfo}
                                toggle={this.toggleProductAnswers()}
                                product={benefit.product}
                                onSubmit={this.handleQuestionsSubmit()}
                            />
                        )}
                    </>
                )
        }

        if (!benefit.isInfoRequired()) {
            return t('cart.no_info')
        }

        const isPending = !additionalInfo || !Object.values(additionalInfo).length

        return (
            <div>
                <div className="mb-3">
                    {isPending
                        ? (
                            <>
                                <img src="/img/error.jpg" alt="cart" width="50"/>
                                <span className="label label-danger">{t('cart.pending')}</span>
                            </>
                        )
                        : (
                            <>
                                <img src="/img/ok.jpg" alt="cart" width="50"/>
                                <span className="label label-success">{t('cart.completed')}</span>
                            </>
                        )
                    }
                </div>

                <div>
                    <button
                        type="button"
                        className="btn btn-default btn-icon"
                        onClick={this.onUpdateInfo}
                    >
                        {t('buttons.update')}
                        <i className="entypo-pencil"/>{' '}
                    </button>
                </div>

                {modal && (
                    <Modal
                        className="cart-edit-modal"
                        isOpen={true}
                        toggle={this.toggleModal}
                    >
                        <ModalHeader toggle={this.toggleModal}/>
                        <ModalBody>
                            <div className="heading4 mb-2">{employeeName}</div>
                            <Panel>
                                {/* show the uploaded file */}
                                {benefit.typeOfQuestionare !== 'upload' && benefit.doc && (
                                    <React.Fragment>
                                        <h4>{t('cart.download_doc_text')}</h4>
                                        <div className="mb-5">
                                            <label className="mr-2">{benefit.doc.name}</label>
                                            <ServerFileLink
                                                src={benefit.doc.file}
                                                className="btn btn-primary"
                                            >
                                                {t('buttons.download')}
                                            </ServerFileLink>
                                        </div>
                                    </React.Fragment>
                                )}

                                {/* display the uploaded file */}
                                {file && (
                                    <React.Fragment>
                                        <h4>Your have the signed document:</h4>
                                        <ServerFileLink src={file.name} className="ml-2 link">
                                            {file.originalFilename}
                                        </ServerFileLink>
                                        <span
                                            role="button"
                                            className="text-danger"
                                            title="Remove"
                                            onClick={this.removeFile}
                                        >
                      <i className="entypo-trash"/>
                    </span>
                                        <div className="mt-3"/>
                                    </React.Fragment>
                                )}

                                {/* display the dropzone */}
                                <Dropzone onChange={this.handleFileUpload}/>
                            </Panel>
                        </ModalBody>
                        <ModalFooter>
                            <button
                                type="submit"
                                className="btn btn-success"
                                onClick={this.handleUpdate}
                            >
                                {t('buttons.update')}
                            </button>
                            {' '}
                            <button
                                type="button"
                                className="btn btn-default"
                                onClick={this.toggleModal}
                            >
                                {t('buttons.cancel')}
                            </button>
                        </ModalFooter>
                    </Modal>
                )}
            </div>
        )
    }
}

export default withTranslation()(AdditionalInfoColumn)
